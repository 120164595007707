<template>
  <div class="login-class">
    <el-form ref="form" :model="form" label-width="80px">
      <el-form-item label="姓名">
        <el-input v-model="form.n"></el-input>
      </el-form-item>
      <el-form-item label="学号">
        <el-input v-model="form.b"></el-input>
      </el-form-item>
      <el-form-item label="密码">
        <el-input v-model="form.p" type="password"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="login">登录</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {},
    };
  },
  mounted() {},
  methods: {
    login() {
      let form = new FormData();
      for (let i in this.form) {
        form.append(i, this.form[i]);
      }
      this.$axios.post("/appealapi/login.php", form).then((res) => {
        if (res.data.s) {
          sessionStorage.setItem("username", res.data.username);
          sessionStorage.setItem("openid", res.data.openid);
          this.$router.push("/");
        } else {
          this.$message.error(res.data);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.login-class {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
</style>